module.exports = {
	services: {
		api: {
			server: `${process.env.REACT_APP_URL_API}`,
		},
		env: {
			host: `${process.env.REACT_APP_ENV}`,
		},
    }
};
