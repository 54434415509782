import { postActualizarDatosDelUsuario } from 'api/users.routes';
import { useGetFamilyData } from 'pages/FormDataFamily/useGetFamilyData';
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useGetLocalidades } from './useGetLocalidades';

export const useFormEditUsers = () => {
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const { localidades } = useGetLocalidades()
    const { familyData, isLoading } = useGetFamilyData()
    const [localidad, setLocalidad] = useState("");
    const location = useLocation()
    
    useEffect(() => {
        if(localidades && familyData){
            const localidadData = localidades.find( (localidad) => localidad.id === familyData?.id_localidad )
            setLocalidad(localidadData.id)
            setValue("id_localidad", localidadData.id)
        }  
    }, [localidades, familyData])
    
    const onSubmit = data => postActualizarDatosDelUsuario({...data})
    .then(() => Swal.fire({
        icon:"success",
        text:"Datos actualizados correctamente",
        timer: 2000
    }))
    .catch((error) => Swal.fire({
        icon:"error",
        text:error,
        timer: 5000
    }))

    const handleLocalidad = (e) => {
        setLocalidad(e.target.value)
        setValue("id_localidad", e.target.value)
    }

    //Los unicos a quienes se les puede editar el role son los admins y usuarios de panel. Los supervisores y familia no.
    const handleRole = (role) => {
        if(location.pathname.includes('edit')){
            setValue("nuevo_role_id", role);
        }else{
            setValue("role_id", role);
        }
      };
  return {
    localidades,
    localidad,
    register, 
    handleSubmit,
    errors,
    isLoading,
    familyData,
    handleLocalidad,
    onSubmit,
    handleRole
  }
}
