import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { FormButtons } from "components/FormButtons";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import React from "react";
import { useLocation } from "react-router-dom";

export const FormEditUsers = ({
  localidades,
  localidad,
  register,
  handleSubmit,
  errors,
  handleLocalidad,
  children,
  onSubmit,
  familyData,
}) => {
  const location = useLocation();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <MDInput
            type="text"
            label="Nombre"
            defaultValue={familyData?.nombre}
            {...register("nombre", {
              required: true,
              maxLength: 20,
              pattern:
                /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/g,
            })}
          />
          {errors.nombre?.type === "required" && (
            <MDTypography color="error">Nombre es obligatorio</MDTypography>
          )}
          {errors.nombre?.type === "pattern" && (
            <MDTypography color="error">Caracteres invalidos, solo acepta letras</MDTypography>
          )}
        </Grid>
        <Grid item xs={6}>
          <MDInput
            type="text"
            label="Apellido"
            defaultValue={familyData?.apellido}
            {...register("apellido", {
              required: true,
              maxLength: 20,
              pattern:
                /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/g,
            })}
          />
          {errors.apellido?.type === "required" && (
            <MDTypography color="error">Apellido es obligatorio</MDTypography>
          )}
          {errors.apellido?.type === "pattern" && (
            <MDTypography color="error">Caracteres invalidos, solo acepta letras</MDTypography>
          )}
        </Grid>
        <Grid item xs={12}>
          <MDInput
            type="email"
            label="Email"
            defaultValue={familyData?.email}
            {...register("email", {
              required: true,
              pattern:
                /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i,
            })}
          />
          {errors.email?.type === "required" && (
            <MDTypography color="error">Email es obligatorio</MDTypography>
          )}
          {errors.email?.type === "pattern" && (
            <MDTypography color="error">Correo invalido</MDTypography>
          )}
        </Grid>
        <Grid item xs={12}>
          <MDInput
            type="text"
            label="Domicilio"
            defaultValue={familyData?.domicilio}
            {...register("domicilio", { required: true, pattern: /([A-Za-z0-9\,\s])+/g })}
          />
          {errors.domicilio?.type === "required" && (
            <MDTypography color="error">Domicilio es obligatorio</MDTypography>
          )}
          {errors.domicilio?.type === "pattern" && (
            <MDTypography color="error">
              Caracteres invalidos, solo acepta letras, numeros y comas
            </MDTypography>
          )}
        </Grid>
        <Grid item xs={6}>
          <FormControl
            fullWidth
            style={{
              height: "100%",
            }}
          >
            <InputLabel id="demo-simple-select-label">Localidad</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={localidad}
              label="Localidad"
              onChange={handleLocalidad}
              style={{
                height: "100%",
              }}
            >
              {localidades &&
                localidades.map((localidad) => (
                  <MenuItem key={localidad.id} value={localidad.id} onClick={() => localidad.id}>
                    {localidad.descripcion}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          {errors.city?.type === "required" && (
            <MDTypography color="error">Localidad es obligatorio</MDTypography>
          )}
        </Grid>
        <Grid item xs={6}>
          <MDInput
            type="number"
            label="DNI"
            defaultValue={familyData?.dni}
            //disabled={location.pathname.includes("edit")}
            {...register("dni", { required: true, min: 1000000 })}
          />
          {errors.dni?.type === "required" && (
            <MDTypography color="error">DNI es obligatorio</MDTypography>
          )}
          {errors.dni?.type === "pattern" && (
            <MDTypography color="error">Caracteres invalidos, solo acepta numeros</MDTypography>
          )}
          {errors.dni?.type === "min" && (
            <MDTypography color="error">DNI muy corto, valor minimo 1000000</MDTypography>
          )}
        </Grid>
        <Grid item xs={6}>
          <MDInput
            type="text"
            label="Barrio / Paraje"
            defaultValue={familyData?.barrio}
            {...register("barrio", { required: true })}
          />
          {errors.barrio?.type === "required" && (
            <MDTypography color="error">Barrio es obligatorio</MDTypography>
          )}
        </Grid>
        <Grid item xs={6}>
          <MDInput
            type="tel"
            label="Telefono"
            defaultValue={familyData?.telefono}
            {...register("telefono", { required: true, pattern: /^[0-9]+$/i, min: 1000000 })}
          />
          {errors.telefono?.type === "required" && (
            <MDTypography color="error">Telefono es obligatorio</MDTypography>
          )}
          {errors.telefono?.type === "pattern" && (
            <MDTypography color="error">Caracteres invalidos, solo acepta numeros</MDTypography>
          )}
          {errors.telefono?.type === "min" && (
            <MDTypography color="error">Verifique que el telefono sea correcto.</MDTypography>
          )}
        </Grid>
        {children}
      </Grid>
      <FormButtons />
    </form>
  );
};
