/* eslint-disable */
import { Box } from "@mui/system";
import { Typography, Input } from "@mui/material";
import { FilterLocalidades } from "components/Filters/FilterLocalidades";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useContext, useEffect, useState } from "react";
import { LocalidadesContext } from "context/LocalidadesProvider";
import { useGetMapData } from "./hooks/useGetMapData";
import { useIsSuspect } from "hooks/useIsSuspect";
import Map from "components/Map/Map";

export default function Maps() {
  const {data, isLoading} = useGetMapData()
  const isSuspect = useIsSuspect();
  const { localidad } = useContext(LocalidadesContext);
  const [fechaDesde, setFechaDesde] = useState("");
  const [fechaHasta, setFechaHasta] = useState("");

    useEffect(() => {
      setFechaDesde("");
      setFechaHasta("");
    }, [data]);

  return (
    <DashboardLayout>
      <p>Mapa</p>
      <Box marginY={5}>
        <FilterLocalidades>
          <Box display={"flex"}>
            <Box>
              {" "}
              <Typography>Desde</Typography>{" "}
              <Input type="date" onChange={(e) => setFechaDesde(e.target.value)} />{" "}
            </Box>
            <Box>
              {" "}
              <Typography>Hasta</Typography>{" "}
              <Input type="date" onChange={(e) => setFechaHasta(e.target.value)} />{" "}
            </Box>
          </Box>
        </FilterLocalidades>
      </Box>
      <Box></Box>
      {isLoading ?
        <h2>Cargando...</h2> :
        <Map data={data} pinType={isSuspect ? "suspect" : "family"} isLoading={isLoading} fechaDesde={fechaDesde} fechaHasta={fechaHasta}/>
       }
    </DashboardLayout>
  );
}