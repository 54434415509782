import React, { useCallback, useContext } from "react";
import { Box, Chip } from "@mui/material";
import { getUsuariosActivos } from "api/users.routes";
import MDButton from "components/MDButton";
import { useFilterUsersByRole } from "hooks/useFilterUsersByRole";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { cambiarEstado } from "helpers/cambiarEstadoDeUsuario";
import Swal from "sweetalert2";
import { LocalidadesContext } from "context/LocalidadesProvider";
/**
 * @param USER_ROL_ID: number
 * @param searchEmpty: boolean
 * @description: useGetDataTable devuelve los usuarios activos segun el ID role, ademas filtra
 * por localidad, el filtro por localidad puede ser obligatorio u opcional haciendo uso del searchEmpty = true
 * de esta manera cargaremos la lista completa de usuarios filtrando por localidad = ''
 */
export const useGetDataTable = (USER_ROL_ID, searchEmpty, fechaDesde, fechaHasta) => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { localidad } = useContext(LocalidadesContext);
  const location = useLocation();

  const searchFunction = useCallback(() => {
    setIsLoading(true);
    getUsuariosActivos({ id_localidad: localidad.id, fecha_desde: fechaDesde, fecha_hasta: fechaHasta })
      .then(({ data }) => {
        setUsers(data || []);
      })
      .catch((error) => {
        if (error.response.status !== 404) {
          Swal.fire({
            icon: "error",
            title: error,
          });
        }
      })
      .finally(() => setIsLoading(false));
  }, [localidad.id]);

  useEffect(() => {
    if (searchEmpty && localidad.id === "") {
      searchFunction();
    } else if (localidad.id !== "") {
      searchFunction();
    }
  }, [localidad]);

  const filtered = useFilterUsersByRole(users, USER_ROL_ID);

  const addActionsButtons = (data) => {
    return data?.map((obj) => {
      obj["program"] = (
        <Box style={{whiteSpace:'normal'}}>
          {obj.ovitrampa && <Chip label="Ovitrampa" variant="outlined" />}
          {obj.aspersor && <Chip label="Aspersor" variant="outlined" />}
        </Box>
      );

      obj["report"] = (
        <Box style={{whiteSpace:'normal'}}>
          {
            location.pathname.includes("supervisores") &&
              <Link
              to={`/supervisores/reportes/${obj.id}`}
            >
              <MDButton
                type="button"
                color="info"
                style={{
                  width: "100%",
                }}
              >
                Ver reportes
              </MDButton>
            </Link>
          }
          {
            obj.ovitrampa &&
            <Link
            to={`/familias/ovitrampa/reportes/${obj.id}`}
          >
            <MDButton
              type="button"
              color="info"
              style={{
                width: "100%",
                margin: '5px 0',
                wordBreack :'keep-all'
              }}
            >
              Ovitrampa
            </MDButton>
          </Link>
          }
          {
            obj.aspersor &&
          <Link
            to={`/familias/aspersor/reportes/${obj.id}`}
          >
            <MDButton
              type="button"
              color="info"
              style={{
                width: "100%",
                margin: '5px 0'
              }}
            >
              Aspersor
            </MDButton>
          </Link>
          }
        </Box>
      );

      obj["acept"] = (
        <Link
          to={
            location.pathname.includes("supervisores")
              ? `/supervisores/edit/${obj.id}`
              : `/familias/edit/${obj.id}`
          }
        >
          <MDButton
            type="button"
            color="warning"
            style={{
              width: "100%",
            }}
          >
            Modificar
          </MDButton>
        </Link>
      );

      obj["delete"] = (
        <MDButton
          type="button"
          color="error"
          onClick={() => cambiarEstado(obj.id, "N", setUsers)}
          style={{
            width: "100%",
          }}
        >
          Deshabilitar
        </MDButton>
      );

      return obj;
    });
  };

  return {
    filtered,
    isLoading,
    addActionsButtons,
  };
};
