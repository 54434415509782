import { serviceApi } from "./api-config"

export const getDatosFamiliesById = async (id) => {
    try{
        const { data } = await serviceApi.get(`api/procev/usuarios/${id}/getUserId`)
        return data
    }catch{
        throw new Error('Error al obtener los datos del id ',id)
    }
}

export const getDatosFamiliesByCode = async (code) => {
    try{
        const { data } = await serviceApi.get(`api/procev/usuarios/${code}/getUserCodigo`)
        return data
    }catch{
        throw new Error('Error al obtener los datos del codigo ',code)
    }
}

export const getDatosFamiliesByCodeAsp = async (code) => {
    try{
        const { data } = await serviceApi.get(`api/procev/usuarios/${code}/getUserCodigoAsp`)
        return data
    }catch{
        throw new Error('Error al obtener los datos del codigo ',code)
    }
}