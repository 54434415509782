import { Box, } from "@mui/system";
import { Typography, Input } from "@mui/material";
import { FilterComponent } from "components/Filters/FilterComponent";
import { FilterLocalidades } from "components/Filters/FilterLocalidades";
import MDButton from "components/MDButton";
import { NoData } from "components/NoData/NoData";
import { LocalidadesContext } from "context/LocalidadesProvider";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useGetDataTable } from "hooks/useGetDataTable";
import Cargando from "pages/Cargando";
import { useContext, useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { familiesTable } from "./familiesTable";
import '../../styles/tableStyle.css'
import { Export } from "components/Buttons/Export";
import { downloadExcel } from "helpers/downloadExcel";

const Families = () => {
  const FAMILIES_ROL_ID = 4;
  const navigate = useNavigate();
  const { filtered, isLoading, addActionsButtons } = useGetDataTable(FAMILIES_ROL_ID, true);
  const { localidad } = useContext(LocalidadesContext);
  const [fechaDesde, setFechaDesde] = useState();
  const [fechaHasta, setFechaHasta] = useState();
  

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  useEffect(() => {
    setFechaDesde("");
    setFechaHasta("");
  }, [localidad]);

  const filteredItems = filtered.filter(item => {
    const fechaCarga = new Date(item.fecha_carga);

    const isWithinDateRange = () => {
      if (fechaDesde && fechaHasta) {
        return fechaCarga >= new Date(fechaDesde) && fechaCarga <= new Date(fechaHasta);
      }
      if (fechaDesde) {
        return fechaCarga >= new Date(fechaDesde);
      }
      if (fechaHasta) {
        return fechaCarga <= new Date(fechaHasta);
      }
      return true;
    };

    const matchesText = Object.keys(item).some(key => {
      if (key !== "id" && item[key]) {
        return item[key].toString().toLowerCase().includes(filterText.toLowerCase());
      }
      return false;
    });

    return matchesText && isWithinDateRange();
  });

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const adapterExcel = (array) => {
    return array.map((data) => {
      const line = {
        id: data.id,
        Nombre: data.nombre,
        Apellido: data.apellido,
        DNI: data.dni,
        Telefono: data.telefono,
        Cantidad: data.cantidad,
        Domicilio: data.domicilio,
        Barrio: data.barrio,
        Email: data.email,
        Localidad: data.localidad,
        "Fecha Carga": data.fecha_carga,
        "Codigo Ovitrampa": data.codigo_ovitrampa,
        "Codigo Aspersor": data.codigo_aspersor 
      };

      return line;
    });
  };

  const actionsMemo = <Export onExport={() => downloadExcel(adapterExcel(filteredItems), "Reportes-Familias")} />;

  if (isLoading) return <Cargando />;
  return (
    <DashboardLayout>
      <Box marginY={4}>
        <FilterLocalidades>
          <Box display={"flex"}>
            <Box>
              {" "}
              <Typography>Desde</Typography>{" "}
              <Input type="date" onChange={(e) => setFechaDesde(e.target.value)} />{" "}
            </Box>
            <Box>
              {" "}
              <Typography>Hasta</Typography>{" "}
              <Input type="date" onChange={(e) => setFechaHasta(e.target.value)} />{" "}
            </Box>
          </Box>
          {/* <MDButton color="info" onClick={handleFilter}>
            Filtrar
          </MDButton> */}
          <MDButton color="success" onClick={() => navigate("/register/familia")}>
            + Agregar familia
          </MDButton>
        </FilterLocalidades>
      </Box>
      {filtered.length > 0 ? (
        <DataTable
          columns={familiesTable}
          data={addActionsButtons(filteredItems)}
          pagination
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          dense={false}
          actions={actionsMemo}
          
        />
      ) : (
        <NoData />
      )}
    </DashboardLayout>
  );
};

export default Families;
